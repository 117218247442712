<template>
    <v-container class="pa-0" style="background:url('/contactbackground.jpg'); background-size:cover">
        <v-row :justify="'center'" :align="'center'" style="height:700px;">
            <v-col cols="12" sm="12" md="12">
                <div class="title text-center">
                    <h2 class="display-3 font-weight-bold white--text">
                        Contact Us
                    </h2>
                    <v-btn
                            color="white"
                            text
                            class="display-1"
                            large
                            @click="contact_dialog = true"
                    >
                        info@wellstartarding.com
                    </v-btn>
                    <p class="mt-12 white--text">
                        WellStar International Trading Ltd. 495 Jiangning Road (Bohong Tower)
                        Suite 801, 200041, Shanghai, China Tel. +86 021-61420155 Email.
                        info@wellstartrading.com
                    </p>
                </div>
            </v-col>
        </v-row>

        <v-dialog v-model="contact_dialog" width="600">
            <v-card>
                <v-card-title>
                    Contact Us
                </v-card-title>
                <v-card-text>
                    <v-text-field
                            v-model="contact.name"
                            :counter="200"
                            required
                            :error-messages="checkName"
                            @input="$v.contact.name.$touch()"
                            @blur="$v.contact.name.$touch()"
                            label="Name"

                    ></v-text-field>

                    <v-text-field
                            v-model="contact.email"
                            :counter="200"
                            required
                            :error-messages="checkEmail"
                            @input="$v.contact.email.$touch()"
                            @blur="$v.contact.email.$touch()"
                            label="Email"
                    ></v-text-field>

                    <v-textarea
                            label="Message"
                            required
                            :error-messages="checkMessage"
                            @input="$v.contact.message.$touch()"
                            @blur="$v.contact.message.$touch()"
                            v-model="contact.message"
                            class=""></v-textarea>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue-grey darken-4"
                            outlined
                            @click="contact_dialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                            color="blue-grey darken-4"
                            outlined
                            @click="send_message()"
                    >
                        Send
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required, maxLength, minLength, email} from 'vuelidate/lib/validators'

    export default {
        components: {},
        mixins: [validationMixin],

        validations: {
            contact: {
                email: {
                    required,
                    email,
                },
                message: {
                    required,
                    maxLength:maxLength(2000),
                    minLength:minLength(50)
                },
                name: {
                    required,
                    maxLength:maxLength(200),
                    minLength:minLength(2)
                }
            }
        },
        computed: {
            checkName() {
                const errors = [];
                if (!this.$v.contact.name.$dirty) return errors;
                !this.$v.contact.name.maxLength && errors.push('contact.name must be at most 200 characters long');
                !this.$v.contact.name.minLength && errors.push('contact.name must be at least 2 characters long');
                !this.$v.contact.name.required && errors.push('contact.name is required.');
                return errors;
            },
            checkEmail() {
                const errors = [];
                if (!this.$v.contact.email.$dirty) return errors;
                !this.$v.contact.email.email && errors.push('contact.email must be a valid email');
                !this.$v.contact.email.required && errors.push('contact.email is required.');
                return errors;
            },
            checkMessage() {
                const errors = [];
                if (!this.$v.contact.message.$dirty) return errors;
                !this.$v.contact.message.maxLength && errors.push('contact.message must be at most 2000 characters long');
                !this.$v.contact.message.minLength && errors.push('contact.message must be at least 50 characters long');
                !this.$v.contact.message.required && errors.push('contact.message is required.');
                return errors;
            },
        },
        data() {
            return {
                contact: {},
                contact_dialog: false
            }

        },
        methods: {
            send_message() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$notify({
                        title: 'Error:',
                        text: 'Please Fill Out The Form Correctly',
                        group: 'notifications',
                        position: 'top right'
                    });
                    return false;
                }

                this.$hpost(this.$http.defaults.baseURL + '/store/contact/', this.contact).then((response) => {
                    if(typeof response.data !== 'undefined')
                    {
                        this.contact_dialog = false;
                        this.$notify({
                            title: 'Success:',
                            text: 'Successfully sent message',
                            group: 'notifications',
                            position: 'top right'
                        });
                        this.contact = {};
                    }
                });
            }
        }
    }
</script>
